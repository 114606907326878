<template>
  <main class="exhibit">
    <ExhibitBanner />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <GoBackFooter
      :routeItem="{ text, arrowString: '&#10230;', routeObj: { name: link } }"
    />
  </main>
</template>

<script>
// Components
import ExhibitBanner from '@/components/Exhibit/ExhibitBanner.vue'
import GoBackFooter from '@/components/GoBackFooter.vue'

// Api
import { apiGetExhibit } from '@/api/webAPI'

export default {
  components: { ExhibitBanner, GoBackFooter },
  data() {
    return {
      textTable: {
        ExhibitNormal: '前往特展',
        ExhibitSpecial: '前往常設展'
      },
      linkTable: {
        ExhibitNormal: 'ExhibitSpecial',
        ExhibitSpecial: 'ExhibitNormal'
      }
    }
  },
  computed: {
    text() {
      const routeName = this.$route.name
      const text = this.textTable[routeName]
      if (!text) return ''
      return text
    },
    link() {
      const routeName = this.$route.name
      const link = this.linkTable[routeName]
      if (!link) return ''
      return link
    }
  },
  async created() {
    const exhibitResponse = await apiGetExhibit()
    const exhibit = exhibitResponse.data.data
    // const exhibit = [
    //   {
    //     title: '常設展',
    //     desc:
    //       '本館常設展匯集地方歷史與自然人文，精采呈現礦山產業百年發展的軌跡。',
    //     exhibit: [
    //       {
    //         title: '黃金館',
    //         desc:
    //           '黃金館為昔日臺灣金屬礦業公司的採礦辦公室，經由結構補強再利用後，作為常設展示館使用，展示水金九地區的礦業人文與自然生態等內容，還有一顆曾獲金氏世界紀錄的220公斤大金磚！',
    //         image:
    //           'https://www.gep.vm.ntpc.gov.tw/upload/exhibition/675be1a2a9d1.jpg',
    //         link: 'https://walkinto.in/tour/ZyItCFCTzD-1l8tAFCaGP/',
    //         start_date: '0000-00-00',
    //         end_date: '0000-00-00'
    //       }
    //     ]
    //   },
    //   {
    //     title: '特展',
    //     desc:
    //       '本館不定期推出主題特展，內容深入淺出，細數今昔的礦山故事、聚落地景以及人文美學。',
    //     exhibit: [
    //       {
    //         title: '枝枒現象–臺灣當代金工與首飾創作譜記',
    //         desc: '',
    //         image:
    //           'https://www.gep.vm.ntpc.gov.tw/upload/exhibition/c8a14c41e866.png',
    //         link: 'http://www.mustdesign.biz/METALCRAFT/index.html',
    //         start_date: '2021-07-01',
    //         end_date: '2022-01-02'
    //       }
    //     ]
    //   }
    // ]

    this.$store.commit('setExhibit', exhibit)
  },
  destroyed() {
    this.$store.commit('setExhibit', null)
  }
}
</script>

<style lang="scss"></style>
