<template>
  <section class="exhibitBanner">
    <template v-if="isNormal">
      <img
        src="@/assets/image/Exhibit/Normal/ExhibitNormalLeft.png"
        alt=""
        class="exhibitBanner__normalLeftImage"
      />
      <img
        src="@/assets/image/Exhibit/Normal/ExhibitNormalRight.png"
        alt=""
        class="exhibitBanner__normalRightImage"
      />
    </template>

    <template v-else>
      <img
        src="@/assets/image/Exhibit/Special/ExhibitSpecialLeftTop.png"
        alt=""
        class="exhibitBanner__specialLeftTopImage"
      />
      <img
        src="@/assets/image/Exhibit/Special/ExhibitSpecialLeftBottom.png"
        alt=""
        class="exhibitBanner__specialLeftBottomImage"
      />
      <img
        src="@/assets/image/Exhibit/Special/ExhibitSpecialRight.png"
        alt=""
        class="exhibitBanner__specialRightImage"
      />
    </template>

    <div class="exhibitBanner__textContainer">
      <h2
        class="exhibitBanner__title"
        :style="`background-image: url(${titleImage});`"
      >
        {{ title }}
      </h2>
      <p class="exhibitBanner__desc">
        {{ desc }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      titleImageTable: {
        ExhibitNormal: require('@/assets/image/Exhibit/Normal/ExhibitNormalTitle.png'),
        ExhibitSpecial: require('@/assets/image/Exhibit/Special/ExhibitSpecialTitle.png')
      },
      titleTable: {
        ExhibitNormal: '常設展',
        ExhibitSpecial: '特展'
      }
    }
  },
  computed: {
    isNormal() {
      const routeName = this.$route.name
      return routeName === 'ExhibitNormal'
    },
    title() {
      const routeName = this.$route.name
      const title = this.titleTable[routeName]
      if (!title) return ''
      return title
    },
    titleImage() {
      const routeName = this.$route.name
      const titleImage = this.titleImageTable[routeName]
      if (!titleImage) return ''
      return titleImage
    },
    descTable() {
      const exhibit = this.$store.state.exhibit
      if (!exhibit) return {}
      return {
        ExhibitNormal: exhibit.find(_exhibit => _exhibit.title === '常設展')
          .desc,
        ExhibitSpecial: exhibit.find(_exhibit => _exhibit.title === '特展').desc
      }
    },
    desc() {
      const routeName = this.$route.name
      const desc = this.descTable[routeName]

      if (!desc) return ''
      return desc
    }
  }
}
</script>

<style lang="scss">
.exhibitBanner {
  background-image: url('~@/assets/image/common/GrayBanner.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 4.5rem 0;
  position: relative;

  &__normalLeftImage {
    position: absolute;
    left: 7.1rem;
    bottom: 0;
    width: 18.9rem;
    height: 7.9rem;
    object-fit: contain;
  }

  &__normalRightImage {
    position: absolute;
    right: 0;
    top: 0;
    width: 13.25rem;
    height: 9rem;
    object-fit: contain;
  }

  &__specialLeftTopImage {
    position: absolute;
    left: 6.8rem;
    top: 0;
    width: 14.5rem;
    height: 8.35rem;
    object-fit: contain;
  }

  &__specialLeftBottomImage {
    position: absolute;
    left: 16.9rem;
    bottom: 0;
    width: 7.25rem;
    height: 8.25rem;
    object-fit: contain;
  }

  &__specialRightImage {
    position: absolute;
    right: 1rem;
    bottom: 0;
    width: 21.6rem;
    height: 8.8rem;
    object-fit: contain;
  }

  &__textContainer {
    width: 50vw;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  &__title {
    flex-shrink: 0;
    background-image: url('~@/assets/image/Exhibit/Normal/ExhibitNormalTitle.png');
    width: 10.7rem;
    height: 4.55rem;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 8.6rem;
  }

  &__desc {
    font-size: 2rem;
    line-height: 3.1rem;
    text-align: justify;
  }
}

@media (max-width: 768px) {
  .exhibitBanner {
    padding: 3.2rem 2rem 8.5rem 2rem;
    position: relative;

    &__title {
      margin: 0 auto;
      margin-bottom: 2.05rem;
    }

    &__textContainer {
      width: 100%;
      flex-direction: column;
      align-content: center;
    }

    &__normalLeftImage {
      left: 0.9rem;
      width: 12.5rem;
      height: 5.3rem;
    }

    &__normalRightImage {
      width: 8.8rem;
      height: 6rem;
    }

    &__specialLeftTopImage {
      left: 0.8rem;
      top: 0;
      width: 9.8rem;
      height: 5.6rem;
    }

    &__specialLeftBottomImage {
      left: 3.3rem;
      bottom: 0;
      width: 4.9rem;
      height: 5.5rem;
    }

    &__specialRightImage {
      right: 0.5rem;
      bottom: 0;
      width: 14.6rem;
      height: 5.9rem;
    }
  }
}
</style>
